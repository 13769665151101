class Stack<T> {
  private items: T[] = []
  private readonly maxSize: number

  constructor(maxSize: number) {
    this.maxSize = maxSize
  }

  push(item: T): void {
    this.items.push(item)

    if (this.items.length > this.maxSize) {
      this.items.shift()
    }
  }

  pop(): T | undefined {
    return this.items.pop()
  }

  peek(): T | undefined {
    return this.items[this.items.length - 1]
  }

  size(): number {
    return this.items.length
  }

  isEmpty(): boolean {
    return this.items.length === 0
  }

  getAll(): T[] {
    return [...this.items]
  }
}

export default Stack
