/* eslint camelcase: 0 */
import { BusinessModel, MenuDisplayStyle, OrderMode } from '@/model/Menu'

export interface BusinessModelDTO {
  id: number
  slug: string
  title: string
  is_opened: boolean
  image: string
  locale: string
  address: string
  address_link: string
  description: string
  processing_time_minutes: number,
  ask_client_phone_number: boolean,
  display_eta: boolean,
  menu_display_style: string
  requires_login_to_order: boolean,
  order_mode: string,
}

export function mapToBusinessModel(business: BusinessModelDTO): BusinessModel {
  return new BusinessModel(
    business.id,
    business.slug,
    business.title,
    business.is_opened,
    business.image,
    business.locale,
    business.description,
    business.address,
    business.address_link,
    business.processing_time_minutes,
    business.ask_client_phone_number,
    business.display_eta,
    asMenuDisplayStyle(business.menu_display_style),
    business.requires_login_to_order,
    asOrderMode(business.order_mode),
  )
}

function asMenuDisplayStyle(style: string): MenuDisplayStyle {
  switch (style) {
    case 'compact':
      return MenuDisplayStyle.COMPACT
    case 'compact_without_image':
      return MenuDisplayStyle.COMPACT_WITHOUT_IMAGE
    default:
      return MenuDisplayStyle.NORMAL
  }
}

function asOrderMode(strOrderMode: string): OrderMode {
  switch (strOrderMode) {
    case 'disabled':
      return OrderMode.DISABLED
    default:
      return OrderMode.REGULAR
  }
}
