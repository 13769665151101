import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  icon: "mdi-plus",
  class: "align-self-center mr-5 ml-5",
  color: "blue-grey-darken-4",
  elevation: "9"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_ctx.item)
    ? (_openBlock(), _createBlock(_component_v_card, {
        key: 0,
        class: "pa-0 ma-0 justify-center rounded-xl",
        elevation: "22"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_v_img, {
              width: "100%",
              "max-height": "400",
              "aspect-ratio": "1/1",
              cover: "",
              src: _ctx.imageUrl
            }, null, 8, ["src"]),
            _createVNode(_component_v_btn, {
              icon: "mdi-close",
              class: "sticky-top ma-2 z-index fab raised rounded-pill",
              color: "blue-grey-darken-4",
              density: "comfortable",
              onClick: _withModifiers(_ctx.onDismiss, ["stop"])
            }, null, 8, ["onClick"])
          ]),
          _createVNode(_component_v_col, { class: "pa-10" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, {
                justify: "space-between",
                class: "pt-6"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.item.title), 1),
                  _createElementVNode("h2", null, _toDisplayString(_ctx.item.priceFormatted), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "pb-10 pt-3 text-left" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.item.details), 1)
                ]),
                _: 1
              }),
              (_ctx.cartEnabled)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    (_ctx.countOnCart() > 0)
                      ? (_openBlock(), _createBlock(_component_v_row, {
                          key: 0,
                          class: "mr-1 mt-1 justify-center",
                          elevation: "9",
                          color: "grey-darken-4"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.countOnCart() == 1)
                              ? (_openBlock(), _createBlock(_component_v_btn, {
                                  key: 0,
                                  icon: "mdi-delete",
                                  class: "z-index fab raised",
                                  color: "blue-grey-darken-4",
                                  elevation: "9",
                                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onMinusFromCart(_ctx.item)), ["stop"]))
                                }))
                              : (_openBlock(), _createBlock(_component_v_btn, {
                                  key: 1,
                                  icon: "mdi-minus",
                                  class: "z-index fab raised",
                                  color: "blue-grey-darken-4",
                                  elevation: "9",
                                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onMinusFromCart(_ctx.item)), ["stop"]))
                                })),
                            _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.countOnCart()), 1),
                            _createVNode(_component_v_btn, {
                              icon: "mdi-plus",
                              class: "z-index fab raised",
                              color: "blue-grey-darken-4",
                              elevation: "9",
                              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.onAddToCart(_ctx.item)), ["stop"]))
                            })
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_v_row, {
                          key: 1,
                          class: "justify-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              "prepend-icon": "mdi-plus",
                              class: "z-index fab raised mr-1 mt-1 rounded-pill",
                              color: "blue-grey-darken-4",
                              size: "large",
                              elevation: "9",
                              onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.onAddToCart(_ctx.item)), ["stop"]))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('item_add')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}