import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import OrderDetailsView from '../views/OrderDetailsView.vue'
import LoginView from '../views/LoginView.vue'
import SignupView from '../views/SignupView.vue'
import ForgotPasswordView from '../views/ForgotPasswordView.vue'
import ConfirmResetPasswordView from '../views/ConfirmResetPasswordView.vue'
import AllOrdersView from '@/views/AllOrdersView.vue'
import { trackScreen } from '@/data/analytics'
import i18n from '@/i18n'

const { t } = i18n.global
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'landing',
    component: () => import(/* webpackChunkName: "landing" */ '@/views/LandingPageView.vue'),
  },
  {
    path: '/:slug',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:slug/item/:item_id',
    name: 'menu_item',
    component: HomeView
  },
  {
    path: '/:slug/checkout',
    name: 'checkout',
    component: () => import(/* webpackChunkName: "checkout" */ '@/views/CheckoutView.vue'),
  },
  {
    path: '/order/:order_id',
    name: 'order',
    component: OrderDetailsView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { title: t('page_login_title') },
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupView,
    meta: { title: t('page_signup_title') },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPasswordView,
    meta: { title: t('page_forgot_password_title') },
  },
  {
    path: '/reset-password/confirm/:u_id/:token',
    name: 'confirm-reset-password',
    component: ConfirmResetPasswordView,
    meta: { title: t('page_forgot_password_title') },
  },
  {
    path: '/:slug/orders',
    name: 'all-orders',
    component: AllOrdersView,
    meta: { title: t('page_orders_title') },
  },
  {
    path: '/bo/about/privacy-policy',
    name: 'bo-privacy-policy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '@/views/legal/bo/privacy/PrivacyPolicyView.vue'),
  },
  {
    path: '/bo/about/terms-and-conditions',
    name: 'bo-terms-and-conditions',
    component: () => import(/* webpackChunkName: "terms-and-conditions" */ '@/views/legal/bo/terms/TermsAndConditionsView.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, _from) => {
  const { title } = to.meta
  if (title) {
    document.title = title as string
  }

  if (to?.name) {
    trackScreen(to.name.toString(), to.name, { path: to.path })
  }
})

export default router
