export default {
  "page_home_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("business"))])},
  "page_orders_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos commandes"])},
  "page_login_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "page_signup_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'enregister"])},
  "page_forgot_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
  "page_checkout_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("business")), " - checkout"])},
  "page_order_details_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("business")), " - votre commande"])},
  "basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "cart_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir mon panier"])},
  "cart_empty_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier est vide"])},
  "cart_go_to_checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier ma commande"])},
  "menu_set_add_btn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ajouter pour ", _interpolate(_named("price"))])},
  "item_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
  "section_menu_sets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menus"])},
  "account_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre email"])},
  "account_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe"])},
  "account_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
  "account_confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le nouveau mot de passe"])},
  "account_update_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le mot de passe"])},
  "account_passwords_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas"])},
  "account_passwords_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a été mis à jour"])},
  "account_reset_password_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un email vous a été envoyé pour réinitialiser votre mot de passe."])},
  "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom"])},
  "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'enregister"])},
  "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
  "account_no_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore inscrit ?"])},
  "account_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjà inscrit ?"])},
  "error_invalid_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champs est invalide"])},
  "error_invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email invalide"])},
  "error_email_already_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette adresse e-mail est déjà utilisée."])},
  "error_signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de l'inscription."])},
  "error_field_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ obligatoire"])},
  "error_week_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe pas assez sécurisé"])},
  "error_invalid_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce numéro de téléphone n'est pas valide"])},
  "error_field_too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est trop long"])},
  "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
  "checkout_review_order": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre commande chez ", _interpolate(_named("name"))])},
  "checkout_submit_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur: la commande n'est pas pu passer"])},
  "checkout_input_details_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos informations"])},
  "checkout_input_name_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom"])},
  "checkout_input_phone_number_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre numéro de téléphone"])},
  "checkout_input_comment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire (optionnel)"])},
  "checkout_input_comment_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos commentaires"])},
  "checkout_establishment_address_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de l'établissement"])},
  "checkout_establishment_address_itinerary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itinéraire"])},
  "checkout_submit_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer la commande"])},
  "order_count_ongoing_orders": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez ", _interpolate(_named("count")), " commandes en cours"])},
  "order_at_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre commande chez"])},
  "order_canceled_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette commande est annulée, à la prochaine fois pour une autre."])},
  "order_done_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette commande est terminée, à la prochaine fois pour une autre."])},
  "order_received_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande reçue"])},
  "order_received_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre commande devrait être préparée sous peu."])},
  "order_picked_up_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande en cours de préparation"])},
  "order_picked_up_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " est en train de traiter votre commande."])},
  "order_picked_up_eta_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elle devrait être prête aux alentours de "])},
  "order_ready_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prête"])},
  "order_ready_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre commande vous attend pour être récupérée."])},
  "order_status_received": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre commande <strong>#", _interpolate(_named("order_number")), "</strong> chez ", _interpolate(_named("business")), " est reçue."])},
  "order_status_in_progress": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre commande <strong>#", _interpolate(_named("order_number")), "</strong> chez ", _interpolate(_named("business")), " est en cours."])},
  "order_status_ready": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre commande <strong>#", _interpolate(_named("order_number")), "</strong> chez ", _interpolate(_named("business")), " est prête."])},
  "order_status_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre commande <strong>#", _interpolate(_named("order_number")), "</strong> chez ", _interpolate(_named("business")), "."])},
  "my_oders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes commandes"])},
  "estimated_waiting_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Temps d'attente estimé: <strong>", _interpolate(_named("time")), "</strong>"])},
  "closed_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, nous somme fermés en ce moment"])},
  "error_invalid_credential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email ou le mot de passe est incorrect."])},
  "you_are_authentifed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes identifié"])},
  "error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups ! Quelque chose s'est mal passé"])},
  "try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])},
  "landing_page_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez Kmandi et transformez l'expérience de votre restaurant"])},
  "landing_page_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez gratuitement"])},
  "landing_page_form_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offrez à vos clients et à votre personnel une gestion des commandes fluide et en temps réel."])},
  "landing_page_form_input_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom"])},
  "landing_page_form_input_business_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de votre établissement"])},
  "landing_page_form_input_business_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de votre établissement"])},
  "landing_page_form_input_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "landing_page_form_input_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "landing_page_form_submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allons-y"])},
  "landing_page_form_submit_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande de connexion a bien été envoyée ! Nous vous répondrons dans les plus brefs délais. Merci de votre confiance."])},
  "landing_page_feat1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes client simplifiées"])},
  "landing_page_feat1_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offrez à vos clients une expérience intuitive pour passer leurs commandes, que ce soit en scannant un QR code à leur table ou depuis chez eux pour un click & collect."])},
  "landing_page_feat2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des commandes simplifiée"])},
  "landing_page_feat2_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplifiez votre flux de travail grâce à un tableau de bord puissant pour gérer facilement les commandes sur place et à emporter."])},
  "landing_page_feat3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mises à jour en temps réel"])},
  "landing_page_feat3_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informez vos clients instantanément du statut de leurs commandes, où qu'ils soient."])},
  "landing_page_feat4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données exploitables"])},
  "landing_page_feat4_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysez les tendances des commandes et les performances de votre restaurant grâce à des données pertinentes pour optimiser vos opérations."])},
  "landing_page_faq_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions fréquentes"])},
  "landing_page_copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2025 Kmandi. Tous droits réservés."])},
  "terms-and-condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions générales"])},
  "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
  "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous contacter"])},
  "landing_page_faq_question_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plateforme est-elle entièrement gratuite ?"])},
  "landing_page_faq_answer_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, la plateforme est entièrement gratuite. Il n'y a aucun frais caché, vous permettant de vous concentrer sur la croissance de votre commerce sans vous soucier de coûts supplémentaires."])},
  "landing_page_faq_question_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment les clients peuvent-ils passer une commande avec un QR code ?"])},
  "landing_page_faq_answer_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les clients scannent simplement le QR code fourni à leur table à l'aide de leur smartphone. Cela ouvre instantanément le menu numérique, où ils peuvent parcourir, sélectionner des articles et passer une commande."])},
  "landing_page_faq_question_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les clients peuvent-ils commander depuis chez eux pour un click & collect ?"])},
  "landing_page_faq_answer_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, les clients peuvent passer leurs commandes confortablement depuis chez eux en utilisant l'application."])},
  "landing_page_faq_question_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment les restaurateurs reçoivent-ils et gèrent-ils les commandes ?"])},
  "landing_page_faq_answer_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les commandes sont instantanément reçues dans un tableau de bord, où les propriétaires et le personnel peuvent les consulter, les gérer et mettre à jour leur statut en temps réel."])},
  "landing_page_faq_question_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'application peut-elle envoyer des notifications en temps réel aux clients ?"])},
  "landing_page_faq_answer_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, les clients reçoivent des mises à jour à chaque étape de leur commande, comme la confirmation, la préparation et la disponibilité pour le retrait ou la livraison."])},
  "landing_page_faq_question_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'application prend-elle en charge plusieurs langues ?"])},
  "landing_page_faq_answer_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, l'application inclut un support en Français et en Anglais pour répondre à une clientèle diversifiée."])},
  "landing_page_faq_question_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment puis-je contacter le support si j'ai besoin d'aide ?"])},
  "landing_page_faq_answer_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez contacter notre équipe d'assistance via le formulaire ci-dessus ou par email à hi", "@", "kmandi.com."])},
  "landing_page_faq_question_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes données sont-elles protégées et seront-elles vendues à des tiers ?"])},
  "landing_page_faq_answer_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolument ! Vos données sont entièrement protégées avec un chiffrement de pointe, et nous garantissons qu'elles ne seront jamais vendues ni partagées avec des tiers. Votre confidentialité est notre priorité."])}
}