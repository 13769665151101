import { SignupEmailExistError } from '@/model/SignupError'
import { checkTokenValidity, getUserIdFromToken, getUserNameFromToken } from '@/utils/jwt'
import { AxiosError, HttpStatusCode } from 'axios'
import { apiConfirmResetPassword, apiForgotPassword, apiLogin, apiRefreshToken, apiSignup, createAnonymousUser } from './api/api'
import { executeCaptcha, CaptchaResult } from './captcha'
import logger from '@/logger/logger'

const USER_ID_KEY = 'user_id'
const ACCESS_TOKEN_KEY = 'acess_token'
const REFRESH_TOKEN_KEY = 'refresh_token'

export class UserRepository {
  private cachedUserName: string | null

  constructor(readonly localStorage: Storage) { }

  get getActiveUserName(): string | null {
    if (!this.cachedUserName) {
      const accessToken = this.localStorage.getItem(ACCESS_TOKEN_KEY)
      if (!accessToken) {
        return null
      }

      this.cachedUserName = getUserNameFromToken(accessToken)
    }
    return this.cachedUserName
  }

  async login(email: string, password: string, rememberUser = true) {
    const token = await apiLogin(email, password)
    this.saveToken(token.access, token.refresh, rememberUser)
  }

  async singup(name: string, email: string, password: string) {
    let captchaResult: CaptchaResult | null = null
    try {
      captchaResult = await executeCaptcha()
    } catch (error) {
      logger.error('Captacha error.', error)
    }

    return apiSignup(name, email, password, captchaResult?.token)
      .then(token => {
        this.saveToken(token.access, token.refresh)
      })
      .catch(error => {
        if (error instanceof AxiosError) {
          const axiosError = error as AxiosError
          if (axiosError.response.status === HttpStatusCode.Conflict) {
            return Promise.reject(new SignupEmailExistError())
          }
        }
        return Promise.reject(error)
      })
  }

  async forgetPassword(email: string, nextUrl: string | null) {
    return apiForgotPassword(email, nextUrl)
  }

  async confirmResetPassword(newPassword: string, userId: string, token: string) {
    return apiConfirmResetPassword(newPassword, userId, token)
  }

  private saveToken(accessToken: string, refreshToken?: string, rememberUser = true) {
    this.localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
    if (rememberUser && refreshToken) {
      this.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    }
  }

  private removeToken() {
    this.localStorage.removeItem(ACCESS_TOKEN_KEY)
    this.localStorage.removeItem(REFRESH_TOKEN_KEY)
  }

  async checkOrRefreshToken(): Promise<boolean> {
    return this.getOrRefreshToken()
      .then((t) => t != null)
  }

  async getOrRefreshToken(): Promise<string> {
    const accessToken = this.localStorage.getItem(ACCESS_TOKEN_KEY)
    if (accessToken && checkTokenValidity(accessToken)) {
      return accessToken
    }
    const refreshToken = this.localStorage.getItem(REFRESH_TOKEN_KEY)
    if (!refreshToken) {
      return null
    }
    if (checkTokenValidity(refreshToken)) {
      return this.regenerateToken(refreshToken)
    }
    return null
  }

  async regenerateToken(refrehToken: string): Promise<string> {
    const token = await apiRefreshToken(refrehToken)
    this.localStorage.setItem(ACCESS_TOKEN_KEY, token.access)
    return token.access
  }

  async logout() {
    this.cachedUserName = null
    this.removeToken()
  }

  async getOrGenerateUserId(): Promise<string> {
    const localUserId = this.getUserId()
    if (localUserId) {
      return localUserId
    }

    let captchaResult: CaptchaResult | null = null
    try {
      captchaResult = await executeCaptcha()
    } catch (error) {
      console.error('Captacha error.', error)
    }
    const newUserId = await createAnonymousUser(captchaResult?.token)
    localStorage.setItem(USER_ID_KEY, newUserId)
    return newUserId
  }

  getUserId(): string | null {
    const accessToken = this.localStorage.getItem(ACCESS_TOKEN_KEY)
    if (!accessToken) {
      const userId = this.getLocalUserId()
      logger.setData('user_id', userId)
      return userId
    }

    const userId = getUserIdFromToken(accessToken)
    if (userId) {
      const id = `user-${userId}`
      logger.setData('user_id', id)
      return id
    }
    return null
  }

  private getLocalUserId(): string | null {
    return localStorage.getItem(USER_ID_KEY)
  }
}

export const userRepository = new UserRepository(localStorage)
