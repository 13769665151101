import checkoutRepository from '@/data/CheckoutRepository'
import { MenuRepository } from '@/data/MenuRepository'
import { userRepository } from '@/data/UserRepository'
import { trackEvent } from '@/data/analytics'
import logger from '@/logger/logger'
import { Cart, CartItem } from '@/model/Cart'
import { MenuModel } from '@/model/Menu'
import { Order, OrderCheckoutEvent } from '@/model/OrderCheckout'
import { OrderStatus } from '@/model/OrderStatus'
import { AxiosError } from 'axios'
import { createStore } from 'vuex'
import { AppState } from './AppState'

const menuRepository = new MenuRepository()

export default createStore<AppState>({
  mutations: {
    SET_MENU(state, model: MenuModel) {
      state.menu = model
      state.requiresLoginToOrder = model.business.requiresLoginToOrder
    },
    SET_CART(state: AppState, cart: Cart) {
      state.cart = cart
    },
    SET_ORDER_STATUS(state: AppState, status: OrderStatus | null) {
      state.orderStatus = status
    },
    SET_CURRENT_ORDER(state: AppState, order: Order) {
      state.currentOrder = order
    },
    SET_ONGOING_ORDERS(state: AppState, orders: Order[]) {
      state.ongoingOrders = orders
    },
    SET_ALL_ORDERS(state: AppState, orders: Order[]) {
      state.allOrders = orders
    },
    SET_ACTIVE_USER_NAME(state: AppState, userName: string) {
      state.activeUserName = userName
    }
  },
  actions: {
    async fetchMenu({ commit }, id: string) {
      const menu = await menuRepository.getMenu(id)
      commit('SET_MENU', menu)
      const cart = await menuRepository.getCart()
      commit('SET_CART', cart)
    },
    async addToCart({ commit }, cartItem: CartItem) {
      const newCart = menuRepository.addToCart(cartItem)
      trackEvent('add_to_cart', { id: cartItem.id })
      commit('SET_CART', newCart)
      logger.info('Add item to cart', cartItem)
    },
    async minusFromCart({ commit }, itemId: string) {
      const newCart = menuRepository.minusFromCart(itemId)
      commit('SET_CART', newCart)
      trackEvent('minus_from_cart', { id: itemId })
      logger.info(`Minus ${itemId} from cart`)
    },
    async removeFromCart({ commit }, itemId: string) {
      const newCart = menuRepository.removeFromCart(itemId)
      commit('SET_CART', newCart)
      logger.info(`Remove ${itemId} from cart`)
    },
    async resetOrderStatus({ commit }) {
      commit('SET_ORDER_STATUS', null)
    },

    async sendOrder({ commit }, order: OrderCheckoutEvent) {
      logger.info('Checkout order', order)
      const cart = this.state.cart
      const business = this.state.menu?.business
      const duplicateOrder = this.state.orderStatus === OrderStatus.Creating || this.state.orderStatus === OrderStatus.Received
      if (cart && business && !duplicateOrder) {
        commit('SET_ORDER_STATUS', OrderStatus.Creating)
        trackEvent('send_order', { business: business.id, store: business.title, count_items: cart.getCountItems() })
        checkoutRepository.sendOrder(order, business, cart)
          .then(order => {
            commit('SET_CURRENT_ORDER', order)
            menuRepository.removeCart()
            commit('SET_ORDER_STATUS', OrderStatus.Received)
          })
          .catch(error => {
            if (error instanceof AxiosError) {
              if (error.code === AxiosError.ERR_BAD_REQUEST) {
                menuRepository.removeCart()
              }
            }
            commit('SET_ORDER_STATUS', OrderStatus.Error)
          })
      } else {
        logger.error('Invalid order status', { cart, business, duplicateOrder })
      }
    },
    async fetchOrder({ commit }, id: string) {
      const order = await checkoutRepository.getOrder(id)
      commit('SET_CURRENT_ORDER', order)
    },
    async fetchOngoingOrders({ commit }, businessSlug: string) {
      const orders = await checkoutRepository.getUserOrders(true, businessSlug)
      commit('SET_ONGOING_ORDERS', orders)
    },
    async fetchAllOrders({ commit }, businessSlug: string) {
      const orders = await checkoutRepository.getUserOrders(false, businessSlug)
      commit('SET_ALL_ORDERS', orders)
    },
    async fetchUserName({ commit }) {
      const userName = userRepository.getActiveUserName
      commit('SET_ACTIVE_USER_NAME', userName)
    }
  },
  getters: {
    getModel(state) {
      return state
    },
    currentOrder(state) {
      return state.currentOrder
    },
    currentMenu(state) {
      return state.menu
    },
    activeUserName(state) {
      return state.activeUserName
    }
  }
})
