import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuItemCompactView = _resolveComponent("MenuItemCompactView")!
  const _component_MenuItemStandardView = _resolveComponent("MenuItemStandardView")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: { name: 'menu_item', params: _ctx.detailsParam },
    custom: ""
  }, {
    default: _withCtx(({ navigate }) => [
      (_ctx.displayCompact || _ctx.displayCompactWithoutImage)
        ? (_openBlock(), _createBlock(_component_MenuItemCompactView, {
            key: 0,
            item: _ctx.item,
            onClick: navigate,
            countOnCart: _ctx.countOnCart,
            onOnAddToCart: _ctx.onAddToCart,
            onOnMinusFromCart: _ctx.onMinusFromCart,
            showImage: _ctx.displayCompact,
            cartEnabled: _ctx.cartEnabled
          }, null, 8, ["item", "onClick", "countOnCart", "onOnAddToCart", "onOnMinusFromCart", "showImage", "cartEnabled"]))
        : (_openBlock(), _createBlock(_component_MenuItemStandardView, {
            key: 1,
            item: _ctx.item,
            onClick: navigate,
            countOnCart: _ctx.countOnCart,
            onOnAddToCart: _ctx.onAddToCart,
            onOnMinusFromCart: _ctx.onMinusFromCart,
            cartEnabled: _ctx.cartEnabled
          }, null, 8, ["item", "onClick", "countOnCart", "onOnAddToCart", "onOnMinusFromCart", "cartEnabled"]))
    ]),
    _: 1
  }, 8, ["to"]))
}