/* eslint camelcase: 0 */
import logger from '@/logger/logger'
import { MenuItemModel, MenuModel, MenuSectionModel, MenuSet, MenuSetCategory, MenuSetItem } from '@/model/Menu'
import { formatPrice } from '../CurrencyFormatter'
import { mapToBusinessModel } from './BusinessModel.dto'
import { MenuItemModelDTO, MenuModelDTO, MenuSectionModelDTO, MenuSetCategoryDTO, MenuSetDTO, MenuSetItemDTO } from './MenuModel.dto'

export function mapDtoToDomain(dto: MenuModelDTO): MenuModel {
  const sections = dto.content.map((sectionDto: MenuSectionModelDTO) => {
    const menuItems = sectionDto.content.map((menuItemDto: MenuItemModelDTO) => {
      const { id, title, price, price_before_discount, details, image } = menuItemDto
      if (id == null || title == null || price == null) {
        logger.error('Invalid menu item DTO', menuItemDto, true)
        return null
      }
      const priceFormatted = formatPrice(price, dto.business.locale)
      const priceBeforeDiscountFormatted = price_before_discount != null ? formatPrice(price_before_discount, dto.business.locale) : null
      const imageOrPlaceholder = image != null ? image : dto.business.image
      return new MenuItemModel(id, title, price, priceFormatted, details, imageOrPlaceholder, price_before_discount, priceBeforeDiscountFormatted)
    }).filter((menuItem) => menuItem)
    const { id, title } = sectionDto
    return new MenuSectionModel(id, title, menuItems)
  })

  const businessModel = mapToBusinessModel(dto.business)
  const menuSet = dto.menu_sets.map((menuSet) => mapMenuSetDTOToClass(menuSet, businessModel.locale))

  return new MenuModel(dto.id, sections, menuSet, businessModel)
}

function mapMenuSetItemDTOToClass(dto: MenuSetItemDTO, locale: string): MenuSetItem {
  return new MenuSetItem(dto.id, dto.product_name, dto.extra_price, formatPrice(dto.extra_price, locale))
}

function mapMenuSetCategoryDTOToClass(dto: MenuSetCategoryDTO, locale: string): MenuSetCategory {
  const possibleItems = dto.possible_items.map((item) => mapMenuSetItemDTOToClass(item, locale))
  return new MenuSetCategory(dto.id, dto.title, dto.max_selection, dto.min_selection, possibleItems)
}

export function mapMenuSetDTOToClass(dto: MenuSetDTO, locale: string): MenuSet {
  const categories = dto.menu_sets_categories.map((category) => mapMenuSetCategoryDTOToClass(category, locale))
  return new MenuSet(dto.id, dto.title, dto.image, dto.initial_price, formatPrice(dto.initial_price, locale), categories)
}
