<template>
  <v-card v-if='item' class="pa-0 ma-0 justify-center rounded-xl" elevation="22">
    <div>
      <v-img width="100%" max-height="400" aspect-ratio="1/1" cover :src=imageUrl />
      <v-btn icon="mdi-close" class="sticky-top ma-2 z-index fab raised rounded-pill" color="blue-grey-darken-4"
        density="comfortable" @click.stop=onDismiss />
    </div>
    <v-col class="pa-10">
      <v-row justify="space-between" class="pt-6">
        <h2>{{ item.title }}</h2>
        <h2>{{ item.priceFormatted }}</h2>
      </v-row>
      <v-row class="pb-10 pt-3 text-left">{{ item.details }}</v-row>
      <div v-if="cartEnabled">
        <v-row v-if="countOnCart() > 0" class=" mr-1 mt-1 justify-center" elevation="9" color="grey-darken-4">
        <v-btn icon="mdi-delete" class="z-index fab raised" color="blue-grey-darken-4" elevation="9"
          @click.stop="onMinusFromCart(item)" v-if="countOnCart() == 1" />
        <v-btn icon="mdi-minus" class="z-index fab raised" color="blue-grey-darken-4" elevation="9"
          @click.stop="onMinusFromCart(item)" v-else />
        <h2 icon="mdi-plus" class="align-self-center mr-5 ml-5" color="blue-grey-darken-4" elevation="9">
          {{ countOnCart() }}
        </h2>
        <v-btn icon="mdi-plus" class="z-index fab raised" color="blue-grey-darken-4" elevation="9"
          @click.stop="onAddToCart(item)" />
      </v-row>
      <v-row class="justify-center" v-else>
        <v-btn prepend-icon="mdi-plus" class="z-index fab raised mr-1 mt-1 rounded-pill" color="blue-grey-darken-4"
          size="large" elevation="9" @click.stop="onAddToCart(item)">
          {{ $t('item_add') }}
        </v-btn>
      </v-row>
      </div>
    </v-col>
  </v-card>
</template>

<script lang='ts'>
import { Cart, CartSingleItem } from '@/model/Cart'
import { MenuItemModel } from '@/model/Menu'
import { Options, Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'

@Options({
  props: {
    item: MenuItemModel,
    onDismiss: Function,
    altImage: String,
    cartEnabled: Boolean
  }
})
export default class MenuItemDetailsView extends Vue {
  item!: MenuItemModel
  onDismiss: () => void
  altImage: string
  cartEnabled!: boolean

  get cart(): Cart | null {
    return this.$store.state.cart
  }

  get imageUrl(): string {
    return this.item.imageUrl == null ? this.altImage : this.item.imageUrl
  }

  mounted(): void {
    const route = useRoute()
    if (this.$store.state.menu == null) {
      this.$store.dispatch('fetchMenu', route.params.slug)
    }
  }

  countOnCart(): number {
    return this.cart?.getItem(this.item.id)?.count || 0
  }

  onAddToCart(menuItem: MenuItemModel): void {
    this.$store.dispatch('addToCart', new CartSingleItem(menuItem.id, 1))
  }

  onMinusFromCart(menuItem: MenuItemModel): void {
    this.$store.dispatch('minusFromCart', menuItem.id)
  }
}
</script>
<style>
.sticky-top {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
