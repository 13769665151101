<template>
  <v-main>
    <div v-if='menu'>
      <BussinessView :business="menu.business">
        <template v-slot:top-right>
          <AccountAccessView />
        </template>
      </BussinessView>
      <v-tabs v-model="tab" align-tabs="center" class="overlay-nav" show-arrows bg-color="background" color="primary">
        <v-tab v-for="section in menu.content" :value="section.id" v-bind:key="section.id"
          @click="tabClicked(section.id)">
          {{ section.title }}
        </v-tab>
      </v-tabs>

      <div class="ma-6 pb-12 mb-12" style="position: relative;">
        <v-row no-gutters width="100%" class="d-flex">
          <v-col :cols=menuListCols>
            <MenuListView :menu="menu" :cart="cart" ref="menu_list" :onSectionIntersection=onSectionIntersection />
            <div style="height: 100px;"></div>
          </v-col>
          <v-col class="ml-12 mt-12" cols="4" v-if=displayCartContent>
            <v-card class="rounded-xl">
              <CartView :cart="cart" :menu="menu" v-if="cart && menu" />
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-col cols="12" class="fixed-bottom pa-5">
        <v-dialog v-if=!displayCartContent v-model="displayBasketDialog" width="" transition="dialog-bottom-transition"
          fullscreen :scrim="false">
          <template v-slot:activator="{ props }">
            <CartBannerView :cart="cart" :menu="menu" v-if="cart && menu" @click="showBasketDialog(true)"
              v-bind="props" />
          </template>
          <CartView :cart="cart" :menu="menu" v-if="cart && menu" :stickyCheckoutBtn="true" height="100%">
            <v-toolbar color="primary">
              <v-app-bar-nav-icon @click="showBasketDialog(false)">
                <v-icon>mdi-close</v-icon>
              </v-app-bar-nav-icon>
              <v-toolbar-title>{{ $t('basket') }}</v-toolbar-title>
            </v-toolbar>
          </CartView>
        </v-dialog>
        <OngoingOrdersView />
      </v-col>
    </div>
    <v-dialog v-model="displayItemDetailsDialog" v-if="itemDetails != null" :fullscreen=showDialogAsFullScreen
      min-width="300" max-width="600">
      <MenuItemDetailsView :item=itemDetails :onDismiss=dismissItemDetails :altImage=menu.business.image
        align="center" />
    </v-dialog>
  </v-main>
</template>

<script lang="ts">
import AccountAccessView from '@/components/AccountAccessView.vue'
import BussinessView from '@/components/BussinessView.vue'
import CartBannerView from '@/components/CartBannerView.vue'
import CartView from '@/components/CartView.vue'
import MenuItemDetailsView from '@/components/menu/MenuItemDetailsView.vue'
import MenuSetDetailsView from '@/components/menu/MenuSetDetailsView.vue'
import MenuListView from '@/components/MenuListView.vue'
import OngoingOrdersView from '@/components/OngoingOrdersView.vue'
import { trackEvent } from '@/data/analytics'
import checkoutRepository from '@/data/CheckoutRepository'
import { Cart } from '@/model/Cart'
import { MenuItemModel, MenuModel } from '@/model/Menu'
import { Options, Vue } from 'vue-class-component'
import { useDisplay } from 'vuetify'

@Options({
  components: { MenuListView, BussinessView, CartView, CartBannerView, OngoingOrdersView, MenuItemDetailsView, AccountAccessView, MenuSetDetailsView }
})
export default class HomeView extends Vue {
  displayBasketDialog = false
  displayItemDetailsDialog = false
  tab = ''
  mobileDisplay = true

  get menu(): MenuModel | null {
    return this.$store.state.menu
  }

  get cart(): Cart | null {
    return this.$store.state.cart
  }

  get menuListCols(): number {
    return this.mobileDisplay ? 12 : 7
  }

  get displayCartContent(): boolean {
    return !this.mobileDisplay
  }

  get showDialogAsFullScreen(): boolean {
    return this.mobileDisplay
  }

  get itemDetails(): MenuItemModel | null {
    if (!this.menu) {
      return null
    }
    if (this.$route.name === 'menu_item') {
      const id = this.$route.params.item_id as string
      return this.menu.getItemById(id)
    }
    return null
  }

  dismissItemDetails(): void {
    this.displayItemDetailsDialog = false
  }

  showBasketDialog(show: boolean): void {
    this.displayBasketDialog = show
  }

  onSectionIntersection(sectionId: string) {
    this.tab = sectionId
  }

  tabClicked(sectionId: string) {
    (this.$refs.menu_list as MenuListView).scrollToSection(sectionId)
    this.tab = sectionId
  }

  mounted(): void {
    const businessSlug = this.$route.params.slug
    this.$store.dispatch('fetchMenu', businessSlug)
    let setupMenuDone = false
    this.displayItemDetailsDialog = this.$route.name === 'menu_item'

    const display = useDisplay()
    this.mobileDisplay = display.smAndDown.value
    this.$watch(() => display.smAndDown.value, (value) => {
      this.mobileDisplay = value
    })

    this.$watch(() => this.$store.state.menu, (menu) => {
      document.title = this.$t('page_home_title', { business: menu.business.title })
      if (!setupMenuDone) {
        setupMenuDone = true
        if (this.itemDetails != null) {
          this.displayItemDetailsDialog = true
        }
      }
    })

    this.$watch(() => this.$route, (r) => {
      const routerName = r.name
      this.displayItemDetailsDialog = (routerName === 'menu_item')
    })

    this.$watch(() => this.displayItemDetailsDialog, (displayItemDetailsDialog) => {
      const navigateBack = !displayItemDetailsDialog
      if (navigateBack) {
        this.$router.push({ name: 'home', params: { slug: businessSlug } })
      }
    })

    const table = this.$route.query.table as string | null
    if (table) {
      checkoutRepository.sentTable(table, businessSlug as string)
      trackEvent('scan_table', { table: table, business: businessSlug })
    }
    if (this.$route.query.qr) {
      trackEvent('scan_qr_code', { business: businessSlug })
    }
  }
}

</script>

<style scoped>
.overlay-nav {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
</style>
