import Stack from '@/core/Stack'
import { getFirebaseApp } from '@/data/firebase'
import { addDoc, collection, getFirestore } from 'firebase/firestore'

class Logger {
  private isProduction = process.env.NODE_ENV === 'production'
  private logs = new Stack<string>(50)
  private userData = {}

  async error(message: string, data?: unknown, report = false): Promise<void> {
    const logs = this.logs.getAll()
    const errorData = {
      message: message,
      error: data,
      logs: logs,
      user_data: this.userData,
      timestamp: new Date().toISOString()
    }
    if (!this.isProduction || !report) {
      if (data instanceof Error) {
        console.groupCollapsed(`[ERROR] ${message}`)
        console.error(errorData)
        console.info('userData', this.userData)
        console.groupEnd()
      } else {
        console.error(`[ERROR] ${message}`, data)
        console.error('errorData', errorData)
      }
      this.logs.push(`[ERROR] ${message} | ${data}`)
      return
    }

    const db = getFirestore(getFirebaseApp())
    try {
      await addDoc(collection(db, 'logs'), errorData)
      console.error(`[PROD ERROR] ${message}`, errorData)
    } catch (firebaseError) {
      console.error('[PROD ERROR] Failed to log error:', firebaseError)
    }
  }

  async info(message: string, ...data: any[]): Promise<void> {
    const timestamp = new Date().toISOString()
    const stringdata = JSON.stringify(data)

    if (!this.isProduction) {
      console.info(`${timestamp} [INFO] ${message}`)
      return
    }
    const logMessage = `${timestamp} [INFO] ${message} | ${stringdata}`
    this.logs.push(logMessage)
  }

  setData(key: string, value: any) {
    this.userData[key] = value
  }
}

const logger = new Logger()
export default logger
