import { mapDtoToDomain } from '@/data/dto/MenuModel.mapper'
import { Cart, CartItem } from '@/model/Cart'
import { MenuModel, OrderMode } from '@/model/Menu'
import { v4 as uuidv4 } from 'uuid'
import { CacheData } from './CacheData'
import { getBusinessMenu } from './api/api'
import logger from '@/logger/logger'

const cartTtl: number = 3 * 24 * 60 * 60

export class MenuRepository {
  private menu?: MenuModel
  private cartCache?: CacheData<any>

  async getMenu(id: string): Promise<MenuModel> {
    if (this.menu) {
      return this.menu
    }
    this.cartCache = new CacheData<any>(`menu_${id}`)
    logger.setData('business_id', id)
    const menuDto = await getBusinessMenu(id)
    const menu = mapDtoToDomain(menuDto)
    this.menu = menu
    return menu
  }

  private updateCart(cart: Cart) {
    if (!this.menu) {
      return
    }
    const serializedCart = cart.serialize()
    this.cartCache?.set(`cart_items_${this.menu.id}`, serializedCart, cartTtl)
  }

  addToCart(item: CartItem): Cart {
    if (!this.menu || this.menu.business.orderMode === OrderMode.DISABLED) {
      return
    }
    const cart = this.getCart()
    cart.addToCart(item)
    this.updateCart(cart)
    return cart
  }

  minusFromCart(itemId: string): Cart {
    if (!this.menu || this.menu.business.orderMode === OrderMode.DISABLED) {
      return
    }
    const cart = this.getCart()
    const cartItem = cart.getCartItemOrEmpty(itemId)
    if (cartItem.count === 1) {
      cart.removeItem(itemId)
    } else {
      cartItem.count--
    }
    this.updateCart(cart)
    return cart
  }

  removeFromCart(itemId: string): Cart {
    if (!this.menu || this.menu.business.orderMode === OrderMode.DISABLED) {
      return
    }
    const cart = this.getCart()
    cart.removeItem(itemId)
    this.updateCart(cart)
    return cart
  }

  getCart(): Cart {
    if (!this.menu || this.menu.business.orderMode === OrderMode.DISABLED) {
      return
    }
    const cart: Cart = this.getCachedCart() || this.createNewCart()
    return cart
  }

  removeCart() {
    this.cartCache?.remove()
  }

  private getCachedCart(): Cart | null {
    if (!this.menu || this.menu.business.orderMode === OrderMode.DISABLED) {
      return null
    }
    const deserializedCart = this.cartCache.get(`cart_items_${this.menu.id}`)
    if (deserializedCart == null) {
      return null
    }
    const cart = Cart.deserialize(deserializedCart)
    return cart
  }

  private createNewCart(): Cart {
    const id = uuidv4()
    return new Cart(id)
  }
}
